<template>
  <div class="bottom">
    <div class="content">
      <div class="bottom-box">
        <div class="right-top">
          <div class="top-title">
            <span>活动</span>
          </div>
          <div class="roll">
            <ul class="roll-ul">
              <li class="roll-li">
                活动时间
                <span></span>
              </li>
              <li class="roll-li">活动名称</li>
              <li class="roll-li">参加人数</li>
            </ul>
            <vue-seamless-scroll :data="listData" class="warp">
              <ul
                class="roll-ul"
                v-for="(item, index) in listData"
                :key="index"
              >
                <li class="roll-lis">{{ item.title }}</li>
                <li class="roll-lis">{{ item.date }}</li>
                <li class="roll-lis">{{ item.value }}</li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="right-top">
          <div class="top-title">
            <span>膳食</span>
          </div>
          <p class="botoom-p">助餐留样</p>
          <div class="roll frame">
            <ul class="roll-ul roll-ul2">
              <li>抽检项目</li>
              <li style="width: 110px;">留样时间</li>
              <li>规格</li>
              <li>数量</li>
              <li>留样人</li>
            </ul>
            <div :data="list" class="warp">
              <ul
                class="roll-ul roll-ul2 roll-active"
                v-for="(item, index) in list"
                :key="index"
              >
                <li class="list-li" style="width: 36px;">{{ item.title }}</li>
                <li class="list-li">{{ item.time }}</li>
                <li class="list-li">{{ item.scale }}</li>
                <li class="list-li">{{ item.value }}</li>
                <li class="list-li">{{ item.name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="right-top">
          <div class="top-title">
            <span>安全</span>
          </div>
          <p class="botoom-p">安全信息</p>
          <div class="roll">
            <vue-seamless-scroll :data="safetyData" class="warp">
              <ul
                class="roll-ul roll-ul2 safety-roll"
                v-for="(item, index) in safetyData"
                :key="index"
              >
                <li class="roll-li2 li2-title">
                  <div class="safety-top"></div>
                  <div class="safety-bottom"></div>
                  {{ item.title }}
                </li>
                <li class="roll-li2 ">
                  <span class="li2-span">
                    <div class="state-top"></div>
                    <span>{{ item.state[0] }}</span>
                  </span>
                  <span class="li2-span">
                    <div class="state-top"></div>
                    <span>{{ item.state[1] }}</span>
                  </span>
                </li>
                <li class="roll-li2">
                  巡检人：{{ item.name[0] }}&emsp;{{ item.name[1] }}
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="right-top">
          <div class="title-right-bottom">
            <span>公共社区实时监控</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vueSeamlessScroll
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      listData: [
        {
          title: '2021.1.29',
          date: '健康与养老',
          value: '22人',
        },
        {
          title: '2021.4.9',
          date: '防疫小知识',
          value: '56人',
        },
        {
          title: '2021.5.9',
          date: '夏季中医养心',
          value: '48人',
        },
        {
          title: '2021.6.29',
          date: '中医排湿',
          value: '66人',
        },
        {
          title: '2021.5.9',
          date: '三伏灸体验',
          value: '48人',
        },
        {
          title: '2021.1.29',
          date: '健康与养老',
          value: '22人',
        },
        {
          title: '2021.4.9',
          date: '防疫小知识',
          value: '56人',
        },
        {
          title: '2021.5.9',
          date: '夏季中医养心',
          value: '48人',
        },
        {
          title: '2021.6.29',
          date: '中医排湿',
          value: '66人',
        },
        {
          title: '2021.5.9',
          date: '三伏灸体验',
          value: '48人',
        },
      ],
      list: [
        {
          title: '早点',
          time: '2021-09-22-07:00:31',
          scale: '125g/份',
          value: '3份',
          name: '杨建军',
        },
        {
          title: '午餐',
          time: '2021-09-22-11:30:31',
          scale: '125g/份',
          value: '4份',
          name: '杨建军',
        },
        {
          title: '下午茶',
          time: '2021-09-22-15:30:31',
          scale: '125g/份',
          value: '1份',
          name: '杨建军',
        },
        {
          title: '晚餐',
          time: '2021-09-22-17:00:31',
          scale: '125g/份',
          value: '4份',
          name: '杨建军',
        },
      ],
      safetyData: [
        {
          title: '消防安全',
          state: '正常',
          name: '王静',
        },
        {
          title: '监控安全',
          state: '正常',
          name: '王静',
        },
        {
          title: '门禁安全',
          state: '正常',
          name: '王静',
        },
        {
          title: '信息安全',
          state: '正常',
          name: '王静',
        },
        {
          title: '环境安全',
          state: '正常',
          name: '王静',
        },
      ],
    }
  },
}
</script>

<style scoped>
.bottom {
  margin: 1rem 0;
}

.content {
  display: flex;
  justify-content: space-between;
}

.bottom-box {
  width: 24.5%;
  height: 320px;
  border: 1px solid rgba(0, 213, 255, 0.1);
  box-shadow: rgba(0, 213, 255, 0.5) 0px 0px 10px 3px inset;
  overflow: hidden;
  border-radius: 1.4%;
}

.top-title {
  background-image: linear-gradient(
    92deg,
    #0825b7 0%,
    #0825b7 0%,
    #01b4ff 100%,
    #01b4ff 100%
  );
  opacity: 0.8;
}

.top-title span {
  display: inline-block;
  font-weight: bolder;
  font-size: 22px;
  background-image: -webkit-linear-gradient(
    0,
    #0072ff 0%,
    #0072ff 0%,
    #00eaff 49%,
    #01aaff 100%,
    #01aaff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1.4% 5%;
}

.title-right-bottom {
  line-height: 1.9rem;
}

.title-right-bottom span {
  color: #ffd725;
  font-size: 18px;
  padding: 2rem;
}

.roll {
  width: 80%;
  height: 80%;
  margin: 5% auto;
}

.roll-ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #ffd725;
}

.roll-ul2 {
  color: #52ccff;
  font-size: 12px;
  margin-top: 2%;
}

.warp {
  overflow: hidden;
  box-sizing: border-box;
}

.roll-lis {
  color: #fff;
  height: 1.6rem;
}

.botoom-p {
  padding: 5% 5% 0 5%;
  color: #ffd725;
  font-size: 16px;
}

.roll-li2 {
  font-size: 15.2px;
  color: #27d2f4;
}

.li2-title {
  display: inline-block;
  height: 19px;
  width: 83px;
  position: relative;
  border: 1px solid rgba(0, 213, 255, 0.1);
  box-shadow: rgba(0, 213, 255, 0.5) 0px 0px 1px 0px inset;
  line-height: 10px;
  padding-left: 8px;
  box-sizing: border-box;
}

.safety-top::after {
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
  right: 0;
  border-top: 1px solid rgba(0, 213, 255, 0.7);
  border-right: 1px solid rgba(0, 213, 255, 0.7);
}

.safety-top::before {
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
  left: 12px;
  border-top: 1px solid rgba(0, 213, 255, 0.7);
  border-left: 1px solid rgba(0, 213, 255, 0.7);
}

.safety-bottom::after {
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 213, 255, 0.7);
  border-right: 1px solid rgba(0, 213, 255, 0.7);
}

.safety-bottom::before {
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
  left: 12px;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 213, 255, 0.7);
  border-left: 1px solid rgba(0, 213, 255, 0.7);
}
.li2-span {
  display: inline-block;
  width: 20px;
  height: 28px;
  line-height: 27px;
  position: relative;
  color: #fff;
}
.li2-span ~ .li2-span {
  margin-left: 3px;
}
.state-top::after {
  position: absolute;
  content: '';
  height: 13px;
  width: 20px;
  background-color: #27d2f4;
  opacity: 0.4;
  border-top-right-radius: 26%;
  border-top-left-radius: 26%;
}
.state-top::before {
  position: absolute;
  content: '';
  height: 13px;
  width: 20px;
  top: 14px;
  background-color: #27d2f4;
  opacity: 0.4;
  border-bottom-right-radius: 26%;
  border-bottom-left-radius: 26%;
}
.li2-span span {
  padding-left: 14%;
}
.frame {
  width: 83%;
  padding: 3% 5%;
  background: url(../../image/center/frame4.png) no-repeat;
  background-size: 100% 100%;
}
.roll-active {
  height: 30px;
}
.list-li {
  color: #fff;
  padding: 0 1%;
}
.roll-active:hover {
  cursor: pointer;
  height: 28px;
  border: 1px solid rgb(0, 213, 255, 0.4);
  box-shadow: rgba(0, 213, 255, 0.5) 0px 0px 20px 0px inset;
}
.safety-roll {
  margin-top: 5%;
}
</style>
