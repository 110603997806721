<template>
  <!-- 将整个大屏分成三部分 -->
  <div class="viewport">
    <!-- 头部 -->
    <ul class="header_top">
      <li>
        <!-- <span >青神养老机构</span> -->
        <span>{{ this.$route.params.title }}</span>
      </li>
      <li>
        <ul class="times_top">
          <li>{{ nowDate }}</li>
          <li>{{ nowWeek }}</li>
          <li>{{ nowTime }}</li>
          <li style="cursor: pointer" @click="buttoncli">
            <img src="../image/top/big.png" alt="" />
            <img src="../image/top/small.png" alt="" />
          </li>
        </ul>
      </li>
    </ul>
    <!-- 主体内容 -->
    <div class="viewmain">
      <contentTop></contentTop>
      <contentBottom></contentBottom>
    </div>
  </div>
</template>
<script>
// 引入全屏模块
import screenfull from 'screenfull'
// 引入主题内容模块
import contentTop from './governmentContent/contentTop.vue'
import contentBottom from './governmentContent/contentBottom.vue'
export default {
  //注册组件
  components: {
    contentTop,
    contentBottom,
  },
  // 定义数据
  data() {
    return {
      //时间
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      //全屏
      isFullscreen: false,
    }
  },
  // dom加载完成后
  mounted() {
    /**
     *
     *
     *
     * 将整个大屏分成三部分
     */
    //刚加载就启动计时器
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
    /**
     *
     *
     * 大屏中间部分
     */
    // 颐伦家会员服务内容分布图
    // this.serviceContent();
    /**
     *
     *
     *
     * 大屏左边部分
     */
    // 颐伦养老机构实时数据-床位数据及服务人员数据
    // this.bedPersonnelData();
  },
  methods: {
    /**
     *
     *
     *
     * 将整个大屏分成三部分
     */
    // 时间
    previousPage() {
      this.$router.push('/')
    },
    setNowTimes() {
      let myDate = new Date()
      // console.log(myDate)
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
    // 全屏
    buttoncli() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning',
        })
        return false
      }
      screenfull.toggle()
    },
  },
}
</script>
<style scoped>
/* 全局样式 */
body {
  line-height: 1.15;
  font-size: 0.5rem;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0 / cover;
  background-color: #101129;
}
* {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.viewport {
  /* background: url(../image/top/bg.png) no-repeat 10% -6.2%; */
  background: url(../image/top/bg1.png) no-repeat;
  /* 限定大小 */
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
  color: #fff;
}
/* 头部 */
.header_top {
  width: 100%;
  height: 3.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #02ceeb;
  background: url(../image/top/bg.png) no-repeat 10% 84.8%;
}
.header_top > li {
  flex: 1;
}
.header_top > li:first-child {
  font-size: 0.6rem;
  text-indent: 0.5rem;
}
.header_top > li:first-child {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.4rem;
}
.header_top > li:first-child > span {
  margin-right: 14.2rem;
  font-weight: bolder;
  background-image: -webkit-linear-gradient(
    0,
    #0072ff 0%,
    #0072ff 0%,
    #00eaff 49%,
    #01aaff 100%,
    #01aaff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header_top > li:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4rem;
}
.times_top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.6rem;
}
.times_top > li {
  margin-right: 0.5rem;
  text-align: center;
}
/* 主体内容 */
.viewmain {
  /* 限定大小 */
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 24px auto;
  display: grid;
  padding: 0 1rem;
}
.previousPage .el-button {
  padding: 0;
}

.previousPage .el-button:hover,
.el-button:focus {
  color: #00d1f2;
  border-color: #00d1f2;
  background-color: #00d1f2;
}

.previousPage .el-button {
  color: #00d1f2;
  border-color: #00d1f2;
  background-color: #00d1f2;
}

.previousPage .el-button--primary {
  background: #00d1f2;
  border-color: #00d1f2;
  color: #00d1f2;
}

.previousPage .el-button--primary:hover,
.el-button--primary:focus {
  background: #00d1f2;
  border-color: #00d1f2;
  color: #00d1f2;
}

.previousPage .el-button--primary:hover,
.el-button--primary:focus {
  background: #00d1f2;
  border-color: #00d1f2;
  color: #00d1f2;
}
</style>
